import Logo from "./logo.svg";

export type Props = Record<string, never>;

function Header() {
  return (
    <header className="mb-16 flex items-center justify-between gap-2 text-xl font-normal">
      <span className="flex items-center py-4">
        <Logo className="-mt-1 mb-1 h-10 w-10" />
        <span className="px-2 font-semibold uppercase">JetBrains Check-In</span>
      </span>
    </header>
  );
}

export default Header;
