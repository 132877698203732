import type { ReactNode } from "react";

export type Props = { children: ReactNode };

export const id = "main";

function Main({ children }: Props) {
  return (
    <main id={id} className="mb-28 flex flex-col items-center">
      {children}
    </main>
  );
}

export default Main;
